import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { scroller } from "react-scroll"
import { Fade } from "react-reveal"
//components
import Layout from "../components/layout"
import Seo from "../components/seo"
import CaseStudiesTopBest from "../components/Home/case-studies-top-best"
import Services from "../components/Home/services"
import TestimonialsVideo from "../components/Home/testimonials-video"
import TestimonialsText from "../components/Home/testimonials-text"
import LiveChat from "../components/Home/livechat"
import Industries from "../components/Home/industries"
import HonorsAchievements from "../components/Home/honors-achievements"
import ContactForm from "../components/Contactus/ContactForm"
import Video from "../components/video";


// images
import gIf2 from "../../images/uploads/Gif-2.gif"
import playButton from "../../images/uploads/play-btn.svg"
import globResult from "../../images/uploads/globresult-arrow.svg"
import Logo from "../images/uploads/decodeup-logo-D.svg"
import decodeCluth from "../../images/uploads/decode-cluth-rev.svg"
import decodeUpwork from "../../images/uploads/decodeup-upwork.svg"
import decodeCluthDark from "../../images/uploads/decode-cluth-rev-dark.svg"
import decodeUpworkDark from "../../images/uploads/decodeup-upwork-dark.svg"

const IndexPage = (props, location) => {
  const [isThemeMB, setIsThemeMB] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [textShowhide, setTextShowhide] = useState(true)
  const [styleShow, setStyleShow] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)

  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(true)
    }, 100)
  }, [])

  const handleVideoIsOpen = (event, index) => {
    setOpenVideoIndex(index)
    setIsVideoOpen(true);
  }
  function handleVideoIsClose(isopenVideoState) {
    setIsVideoOpen(isopenVideoState);
  }

  const togglePopup = () => {
    setIsActive(!isActive)
    document.body.classList.add("dec-backdrop")
  }

  const closetogglePopup = () => {
    setIsActive(!isActive)
    document.body.classList.remove("dec-backdrop")
  }

  // Text animation
  const textAnimation = () => {
    setTextShowhide(false)
    setStyleShow(true)
  }

  const closeHideshow = () => {
    setTextShowhide(true)
    setStyleShow(false)
  }

  const { data } = props
  const SEOdata = data.homeData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.homeData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.homeData.edges[0].node.frontmatter?.Hreflang

  const videoURL = data.homeData.edges[0].node.frontmatter?.video_section.Homepage_video

  console.log('videoURL', videoURL)
  const sendStateToLayout = isThemefromLayout => {
    setIsThemeMB(isThemefromLayout)
  }

  const scrollToSection = () => {
    scroller.scrollTo("case-studies-main", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    })
  }

  return (
    <>
      <Layout
        sendStateToLayout={sendStateToLayout}
        closeHideshow={closeHideshow}
      >
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Home"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={
            SEOdata
              ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images
                ?.fallback?.src
              : ""
          }
          single_image={SEOdata ? SEOdata.seo_Single_Image : ""}
          currentPageUrlPath={location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={
            SEOdata ? SEOdata.twitter_estimated_reading_lable : ""
          }
          twitter_estimated_reading_time={
            SEOdata ? SEOdata.twitter_estimated_reading_time : ""
          }
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={
            SEOdata ? SEOdata.page_last_modified_time : ""
          }
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={
            Schemadata ? Schemadata.aggregate_rating_schema : ""
          }
          local_business_schema={
            Schemadata ? Schemadata.local_business_schema : ""
          }
          site_navigation_schema={
            Schemadata ? Schemadata.site_navigation_schema : ""
          }
          speakable_specification_schema={
            Schemadata ? Schemadata.speakable_specification_schema : ""
          }
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />
        {!shouldAnimate && !shouldAnimate ? (
          <div className="min-h-screen"> </div>
        ) : (
          <section className="bg-primary lg:h-screen lg:min-h-860 lg:max-h-960 relative">
            <div className="container mx-auto h-full relative">
              <div className="h-full flex justify-center items-center flex-col md:flex-row gap-16 decodeup-hero">
                <div
                  className="w-full lg:w-3/5 xl:w-2/3 lg:flex-1 decodeup-hero-inner"
                  onClick={closeHideshow}
                >
                  {/* Main content */}
                  <Video isVideoOpen={isVideoOpen} openVideoIndex={openVideoIndex} onChange={handleVideoIsClose} videoSrcURL={videoURL} elementIndex={1} />
                  <div
                    className="res-main-content"
                    style={{ display: styleShow ? "none" : "block" }}
                  >
                    <div className="mb-10 md:mb-12 xl:mb-16">
                      <Fade bottom distance="60px" delay={100}>
                        <Fade
                          bottom
                          distance="60px"
                          delay={100}
                          when={textShowhide}
                        >
                          <h1 className="pr-4 mb-3 md:mb-6 xl:mb-8 font-poppings-bold text-srh2 xl:text-h1 text-term-primary text-left">
                            <span>
                              We decode complex challenges and develop digital
                              solutions to
                            </span>
                            <span className="text-cyan">
                              &nbsp; drive global growth for SMBs
                            </span>
                          </h1>
                        </Fade>
                      </Fade>
                      <Fade bottom distance="60px" delay={400}>
                        <Fade
                          bottom
                          distance="60px"
                          delay={400}
                          when={textShowhide}
                        >
                          <p className="lg:pr-3 xl:pr-0 font-worksans-normal text-p4 lg:text-p2 text-term-primary text-left">
                            DecodeUp digitalize small and medium-sized
                            businesses & we offer every client creative
                            solutions that increase growth. Experience our
                            trusted approach to develop websites, applications &
                            brands, which is grounded in accountability,
                            integrity and a passion for digital development.
                          </p>
                        </Fade>
                      </Fade>
                    </div>
                    <Fade bottom distance="60px" delay={700}>
                      <Fade
                        bottom
                        distance="60px"
                        delay={700}
                        when={textShowhide}
                      >
                        <div className="w-full flex items-center flex-wrap md:flex-nowrap lg:flex-row gap-6 lg:gap-10" >
                          <button
                      
                            className="w-4_6 md:w-3_0 order-2 lg:order-1 lg:w-full lg:max-w-3-52 flex flex-row items-center bg-background-quinary lg:bg-transparent gifButton border-0 lg:border-1" onKeyDown={(event) => handleVideoIsOpen(event, 1)} onClick={(event) => handleVideoIsOpen(event, 1)}
                          >
                            <div className="hidden xl:block relative  gifButtonImg">
                              <img
                                src={gIf2}
                                className="w-full h-full gif-img"
                                alt=""
                              />
                              <img
                                src={playButton}
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
                                alt=""
                              />
                            </div>
                            <span className="py-3 lg:p-4 flex-1 flex items-center justify-center text-term-primary font-poppings-bold text-rh3 md:text-h4">
                              Corporate Video
                            </span>
                          </button>

                          <div className=" w-full md:w-3_0 order-3 lg:order-2 lg:w-full lg:max-w-3-52 flex flex-row items-center  justify-center ">
                            <Link
                              to="decodeup-globe-presents"
                              className="p-5 lg:p-0 flex flex-row items-center justify-center gap-2 text-cyan font-poppings-bold text-rh3 md:text-h4"
                            >
                              Global Result
                              <img src={globResult} alt="" />
                            </Link>
                          </div>

                          <button
                            onClick={togglePopup}
                            className="w-4_6 md:w-3_0 block order-1 lg:order-3 lg:hidden p-3 bg-background-quaternary text-white font-poppings-bold text-rh3 md:text-h4 text-center cursor-pointer"
                          >
                            Connect With Us
                          </button>
                        </div>
                      </Fade>
                    </Fade>
                  </div>

                  {/* Toggle content*/}

                  <div
                    className="res-toggle-content"
                    style={{ display: styleShow ? "block" : "none" }}
                  >
                    <Fade bottom distance="60px" when={!textShowhide}>
                      <div className="hidden lg:block pl-20 xl:pl-1-04">
                        <div className="w-full ">
                          <Fade
                            delay={100}
                            bottom
                            distance="60px"
                            when={!textShowhide}
                          >
                            <h4 className="mb-4 text-h4 text-cyan font-poppings-bold uppercase">
                              LET’S CONNECT
                            </h4>
                          </Fade>
                          <Fade
                            delay={400}
                            bottom
                            distance="60px"
                            when={!textShowhide}
                          >
                            <h1 className="mb-3 md:mb-6 xl:mb-8 font-poppings-bold text-rh2 xl:text-h1 text-term-primary text-center md:text-left">
                              Let’s discuss to grow your business{" "}
                            </h1>
                          </Fade>
                          <Fade
                            delay={700}
                            bottom
                            distance="60px"
                            when={!textShowhide}
                          >
                            <ul className="mb-12 flex flex-col gap-3 list-none text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2 dec-cstm-li">
                              <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                                Tell as about your requirement.
                              </li>
                              <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                                Brief us about your business.
                              </li>
                              <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                                Find out if we’re a good fit.
                              </li>
                              <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                                Get the details about our services.
                              </li>
                            </ul>
                          </Fade>
                          <Fade
                            delay={1000}
                            bottom
                            distance="60px"
                            when={!textShowhide}
                          >
                            <div className="mb-12">
                              <h4 className="mb-4 text-h4 text-term-primary font-poppings-bold ">
                                Our Trusted Reviews
                              </h4>
                              <div className="flex items-center">
                                <div className="pr-4 lg:pr-6 ">
                                  <img
                                    className="w-full h-full img-light "
                                    src={decodeCluth}
                                    alt="DecodeUp logo"
                                  />
                                  <img
                                    className="w-full h-full img-dark "
                                    src={decodeCluthDark}
                                    alt="DecodeUp logo"
                                  />
                                </div>
                                <div className="pl-4 lg:pl-6 border-l ">
                                  <img
                                    className="w-full h-full img-light"
                                    src={decodeUpwork}
                                    alt="DecodeUp logo"
                                  />
                                  <img
                                    className="w-full h-full img-dark"
                                    src={decodeUpworkDark}
                                    alt="DecodeUp logo"
                                  />
                                </div>
                              </div>
                            </div>
                          </Fade>
                          <Fade
                            delay={1300}
                            bottom
                            distance="60px"
                            when={!textShowhide}
                          >
                            <div>
                              <h4 className="mb-0 text-h4 text-term-primary font-poppings-bold ">
                                Connect With Us
                              </h4>
                              <div>
                                <a
                                  href={`mailto:sales@decodeup.com`}
                                  className="text-rp3 lg:text-p3 text-term-primary font-worksans-normal "
                                >
                                  sales@decodeup.com
                                </a>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div
                    className="overflow-click-event"
                    style={{ display: styleShow ? "block" : "none" }}
                  ></div>
                </div>

                {/* form */}

                <div
                  className={`hidden lg:block w-full lg:w-2/5 xl:w-1/3 ${isActive ? "form-open" : ""
                    }`}
                >
                  <div className="deco-res">
                    <div className="left-1/2 transform -translate-x-1/2 block lg:hidden container mx-auto absolute w-full z-10">
                      <div className="w-full py-5 flex justify-between items-center">
                        <Link to="/" className="inline-block">
                          <img
                            className="w-12 md:w-81 md:h-63 "
                            src={Logo}
                            alt="DecodeUp logo"
                          />
                        </Link>
                        <button type="button" onClick={closetogglePopup}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="svg-model-close"
                          >
                            <path d="M6.28 33.869a1.583 1.583 0 0 1 0-2.264L31.555 6.712a1.642 1.642 0 0 1 2.298 0 1.583 1.583 0 0 1 0 2.263L8.578 33.87a1.642 1.642 0 0 1-2.297 0Z" />
                            <path d="M6.15 6.132a1.642 1.642 0 0 1 2.297 0L33.72 31.025a1.583 1.583 0 0 1 0 2.263 1.642 1.642 0 0 1-2.297 0L6.148 8.395a1.583 1.583 0 0 1 0-2.263Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <Fade bottom distance="60px" delay={100}>
                      <div className="dec-lead-form" onClick={textAnimation}>
                        <ContactForm shoefunction={textAnimation} />
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="lg:pt-0 relative z-10" onClick={closeHideshow}>
          <div className="container mx-auto">
            <Fade bottom distance="100px">
              <div
                id="case-studies"
                className="pb-6-6 lg:pb-60 mx-auto case-studies-main cstm-main-sec"
              >
                <h2 className="text-center font-poppings-bold text-rh2 lg:text-h2 text-term-primary">
                  <Fade bottom distance="60px">
                    Development Analysis
                  </Fade>
                </h2>
                <Fade bottom distance="60px" delay={300}>
                  <p className="pt-3-3 lg:pt-6-6 xl:px-1-10 text-center mx-auto font-worksans-normal text-p4 lg:text-p2 text-term-primary">
                    We are constantly improving our methods of web and app
                    development. These case studies detail our approach to
                    design, philosophy on overcoming challenges and details
                    about proven methods we use to deliver big for our clients.
                  </p>
                </Fade>
              </div>
            </Fade>
          </div>
        </section>

        {/* our portfolio carousel section */}
        <div onClick={closeHideshow}>
          <CaseStudiesTopBest />
        </div>

        <div onClick={closeHideshow}>
          {/* services section */}
          <Services />
        </div>

        <div onClick={closeHideshow}>
          {/* industries we serve carousel section */}
          <Industries />
        </div>

        {/* client video testimonials carousel section */}
        <div onClick={closeHideshow}>
          <section>
            <div className="container mx-auto bg-alumn-500">
              {/* <div className="container mx-auto"> */}
              <TestimonialsVideo />
            </div>
          </section>
        </div>

        <div onClick={closeHideshow}>
          {/* client testimonial carousel section */}
          <section>
            <div className="container mx-auto">
              <div
                className=" pt-5 pb-53 pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 lg:pt-0 lg:pb-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87
            bg-alumn-500"
              >
                <TestimonialsText />
              </div>
            </div>
          </section>
        </div>

        <div onClick={closeHideshow}>
          {/* honors and achivements section */}
          <HonorsAchievements />
        </div>

        <div onClick={closeHideshow}>
          {/*schedule a free cosultation chat section */}
          <LiveChat />
        </div>
      </Layout>

      {/* {shouldAnimate && (
      )} */}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    homeData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/home.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
            video_section {
              Homepage_video
            }
          }
        }
      }
    }
  }
`
