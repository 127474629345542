import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby"
import {Fade} from 'react-reveal'

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      mainServices: allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___date] }
        filter: {fileAbsolutePath: {regex: "/content/services/"}}
        ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              meta_values {
                alt
                excerpt
                svg {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const services = data.mainServices.edges
  return (
    <>
      <Fade bottom distance="60px" delay={100}>
        <section className="bg-background-primary">
          <div className="container mx-auto">
            <div className="pt-10 relative flex flex-wrap du-service-dots">
              <div className="px-6 py-16 md:py-10 xl:px-70 xl:py-84 w-full md:w-1/2 bg-cyan">
                <Fade bottom distance="60px" delay={100}>
                  <h6 className="font-poppings-medium text-white text-rh6 lg:text-h6 opacity-60">
                    OUR SERVICES
                  </h6>
                </Fade>
                <Fade bottom distance="60px" delay={100}>
                  <h2 className="pt-1 pb-3 md:pt-4 md:pb-5-1 font-poppings-bold text-rh2 lg:text-h3 xl:text-h2 text-white">
                    Take Your Business From 0 To 100 With A Sleek Digital
                    Showcase
                  </h2>
                </Fade>
                <Fade bottom distance="60px" delay={100}>
                  <p className="pb-6-5 mb:pb-10 lg:pb-53 font-worksans-normal w-full  text-p4 lg:text-p2 text-white">
                    Our roster of digital services are unique for their
                    attention to detail and reputation for success. We rely on
                    data to guide our process in helping each client produce
                    the most stunning websites, apps and strategies.
                  </p>
                </Fade>
                <Fade bottom distance="60px" delay={100}>
                  <div>
                    <Link
                      to="/get-in-touch"
                      className="py-2-2 px-6-1 lg:pl-10 lg:pr-41 lg:py-3 font-poppings-bold inline-block text-white text-rh5 lg:text-h5 border border-white hover:border-transparent hover:bg-white hover:text-cyan transition-all duration-300 ease-in-out delay-0"
                    >
                      Get Started
                    </Link>
                  </div>
                </Fade>
              </div>
              <div className="px-6 py-16 md:py-10 xl:px-70 xl:py-84 w-full md:w-1/2 bg-background-quaternary  flex flex-col gap-4 xl:gap-6-6">
                {services.map(({ node: service }, i) => (
                  <div key={i} className="flex gap-3 lg:gap-5">
                    <Fade bottom distance="60px" delay={100}>
                      <div className="w-6-6 h-6-6 md:w-10 md:h-10">
                        <img className="w-full h-full"
                          src={service.frontmatter.meta_values.svg.publicURL}
                          alt={service.frontmatter.meta_values.alt}
                        />
                      </div>
                    </Fade>
                    <div className="mt-0-3 md:mt-1 lg:mt-2 ml-1 md:ml-2 lg:ml-0 flex-1 ">
                      <Fade bottom distance="60px" delay={100}>
                        <Link
                          to={service.fields.slug}
                          className="mb-0 md:mb-1 lg:mb-1-3 block font-poppings-bold text-rh3 1md:text-h4 xl:text-sh3 text-white"
                        >
                          {service.frontmatter.title}
                        </Link>
                      </Fade>
                      <Fade bottom distance="60px" delay={100}>
                        <p className="font-worksans-normal text-p4 lg:text-p2 text-white opacity-60">
                          {service.frontmatter.meta_values.excerpt}
                        </p>
                      </Fade>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Fade>
    </>
  );
}

export default Services;
