import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MarkDown from "react-markdown"
import { Fade } from "react-reveal"

const CaseStudiesTopBest = () => {
  const data = useStaticQuery(graphql`
    query {
      content: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          frontmatter: {
            templateKey: { eq: "single-case-study" }
            set_as_feature: { eq: "Best" }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              country
              slug
              short_details {
                heading
                excerpt
                featured_image_home {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
              set_as_feature
            }
          }
        }
      }
    }
  `)
  const info = data.content.edges

  return (
    <>
      <section>
        <div className=" pt-5 pb-70 lg:pt-0 lg:pb-20 xl:pb-1-20 container mx-auto">
          <div className="h-case-studies-container">
            {info &&
              info.map(({ node: post }, i) => (
                <div
                  key={i}
                  className={`${i % 2 === 0 ? "bg-background-case" : "bg-cyan"
                    } mx-auto mb-10 lg:mb-50 xl:mb-20 h-case-study-main overflow-hidden`}
                >
                  <Link to={post.frontmatter.slug} className="block w-full">
                    <div className="xl:h-5-00 flex flex-wrap xl:flex-nowrap justify-between items-center de-case-card">

                      <div
                        className={`pt-6-6 px-5-3 md:pt-0 md:pl-10 md:pr-6-6 lg:pl-60 xl:pl-70 lg:pr-0 w-full sm:w-1/2 xl:w-4-50 de-case-card-content`}
                      >
                        <Fade bottom big distance="60px" delay={100}>
                          <span className="font-poppings-normal text-white text-rh6 lg:text-rh5 lg:text-h4 w-full">
                            {post.frontmatter.short_details.heading}
                          </span>
                        </Fade>
                        <Fade bottom distance="60px" delay={100}>
                          <h2 className="mt-0-1 mb-2 md:mt-1-3 lg:mt-2-2 md:mb-3-4 xl:mb-5 font-poppings-bold text-white text-rh2 sm:text-rp1 md:text-sh3 lg:text-rh2 xl:text-h2 w-full">
                            {post.frontmatter.title}
                          </h2>
                        </Fade>
                        <Fade bottom distance="60px" delay={100}>
                          <MarkDown
                            className="pb-5 lg:pb-10 lg:pr-6-2 font-worksans-normal text-white text-p4 lg:text-p2 "
                            children={post.frontmatter.short_details.excerpt}
                            allowDangerousHtml
                          />
                        </Fade>
                        <Fade bottom distance="60px" delay={100}>
                          <div className="py-3 px-6 inline-block bg-silver-10">
                            <div className="font-poppings-bold uppercase text-white text-opacity-60 text-p4">
                              {post.frontmatter.country}
                            </div>
                          </div>
                        </Fade>
                      </div>
                      <div className="mt-6-6 sm:mt-0 case-study-top-best-img-sec w-full sm:w-1/2 sm:h-3-00 1md:h-auto md:overflow-hidden xl:w-5-80 xl:h-500">
                        <Fade bottom distance="60px" delay={100}>
                          <GatsbyImage
                            image={getImage(
                              post.frontmatter.short_details.featured_image_home
                                .image
                            )}
                            alt={post.frontmatter.short_details.featured_image_home.alt}
                          />
                        </Fade>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          <Fade bottom distance="60px" delay={100}>
            <div className="lg:py-5 text-center">
              <Link
                to="/case-studies"
                className="py-3 pl-52 pr-54 inline-block font-poppings-bold text-center text-dark-blue text-h5 bg-celeste shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
              >
                View All
              </Link>
            </div>
          </Fade>
        </div>
      </section>
    </>
  )
}

export default CaseStudiesTopBest
