import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Flickity from 'react-flickity-component'
import 'flickity-as-nav-for';
import 'flickity/dist/flickity.min.css';
import { Fade } from "react-reveal";

const Industries = () =>
{
  const data = useStaticQuery(graphql`
  query {
    normalPost: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "industry" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              excerpt
              featured_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  let flktyy = null;
  useEffect(() =>
  {
    const onShadowchange = () =>
    {
      var e_sld = document.querySelectorAll('.e-shadow');
      var left_s = document.getElementById("left1");
      var right_s = document.getElementById("right1");
      if (e_sld !== null && left_s !== null && right_s !== null)
      {
        flktyy.on('scroll', function (progress)
        {

          if (progress >= 0.9999)
          {
            right_s.classList.remove("e-shadow-right");
          } else
          {
            right_s.classList.add("e-shadow-right");
          }
          if (progress <= 0.011)
          {
            left_s.classList.remove("e-shadow-left");
          } else
          {
            left_s.classList.add("e-shadow-left");
          }
        })
      }
    }
    onShadowchange()
  }, [flktyy]);
  const flickityOptions = {
    contain: true,
    prevNextButtons: true,
    pageDots: false,
    cellAlign: 'left',
  }
  const flickityNavOptions = {
    asNavFor: '.fusion-tab-carousel',
    contain: true,
    prevNextButtons: false,
    pageDots: false,
  }
  const posts = data.normalPost.edges
  return (
    <section className="pt-50 pb-50 md:py-60 lg:pt-1-40 lg:pb-20 min-h-450 industrie-serve-section ">
     <Fade bottom distance="60px" delay={100}>
      <div className="industries-wrapper">
        <div className="container mx-auto">
          <div className="mb-5-2 industries-heading text-center">
          <Fade bottom distance="60px" delay={100}>
            <h3 className="text-rp1 md:text-h3 font-poppings-bold text-term-primary">
              Industries We Serve{" "}
            </h3>
            </Fade>
          </div>
        </div>
        {/* slider nav */}
        <div className="industry-nav-main">
          <div className="container mx-auto">
          <Fade bottom distance="60px" delay={100}>
            <div className="fusion-carousel__container">
              <div className="e-shadow relative" id="left1"></div>
              <div className="e-shadow relative" id="right1"></div>
              <Flickity
                flickityRef={c => (flktyy = c)}
                className={
                  "fusion-carousel-nav py-4 xl:py-5 border-t border-b border-tone-quaternary"
                }
                elementType={"div"}
                options={flickityNavOptions}
              >
                {posts &&
                  posts.map(({ node: postNav }, i) => (
                    <div
                      key={i}
                      className="fusion-nav-cell text-p3 xl:text-h4 font-poppings-bold text-term-primary hover:text-cyan cursor-pointer"
                    >
                      <p>{postNav.frontmatter.title}</p>
                    </div>
                  ))}
              </Flickity>
            </div>
            </Fade>
          </div>
        </div>
        {/* slider-section */}
        <div className="container mx-auto">
        <Fade bottom distance="60px" delay={100}>
          <div className="pt-12 pb-0 lg:pt-10 xl:pt-60">
            <Flickity
              className={"md:px-50 1md:px-60 2xl:px-1-05 fusion-tab-carousel"}
              elementType={"div"}
              options={flickityOptions}
            >
              {posts &&
                posts.map(({ node: post }, j) => (
                  <div
                    key={j}
                    className="mx-3 md:mx-0 fusion-cell flex flex-wrap items-center justify-between"
                  >
                    <div className="md:pl-5 md:w-1/2">
                      <GatsbyImage
                        image={getImage(post.frontmatter.featured_image.image)}
                        alt={
                          post.frontmatter.featured_image.alt ||
                          `${post.frontmatter.title}`
                        }
                      />
                    </div>
                    <div className="mt-5 md:pl-6-6 2md:pl-60 md:pr-5 md:mt-0 md:w-1/2">
                      <h2 className="text-left pb-1-2 md:pb-3 text-rh2 xl:text-h2 text-term-primary font-poppings-bold">
                        {post.frontmatter.title}
                      </h2>
                      <p className="text-left pr-3 pb-0 md:px-0 lg:pb-6-2 text-p4 xl:text-p2 xl:w-3-87 text-term-primary font-worksans-normal">
                        {post.frontmatter.excerpt}
                      </p>
                    </div>
                  </div>
                ))}
            </Flickity>
          </div>
          </Fade>
        </div>
      </div>
      </Fade>
    </section>
  )
}

export default Industries;