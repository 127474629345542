
import React, {useState} from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';
import 'flickity-as-nav-for';
import { Fade } from "react-reveal";

import Video from "../video"

const TestimonialsVideo = () => {
  const data = useStaticQuery(graphql`
    query {
      video_testi: allMarkdownRemark(
        limit: 7
        sort: { order: ASC, fields: [frontmatter___date] }
        filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {eq: "CVTT"}}}
        ) {
          edges {
            node {
              html
              frontmatter {
                title
                description
                upload_video
                featured_image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                      )
                    }
                  }
                  thumbnail {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
  const content = data.video_testi.edges

  const flickityOptions = {
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    wrapAround: true,
  }
  const flickityNavOptions = {
    asNavFor: '.s-testimonial-video-main',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
  }
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)
  const handleVideoIsOpen = (event, index) => {
    setOpenVideoIndex(index)
    setIsVideoOpen(true);
  }
  function handleVideoIsClose(isopenVideoState) {
    setIsVideoOpen(isopenVideoState);
  }
  return (
    <>
      {content &&
        content.map(({ node: video }, v) => (
          <div key={v}>
            <Video isVideoOpen={isVideoOpen} openVideoIndex={openVideoIndex} onChange={handleVideoIsClose} videoSrcURL={video.frontmatter.upload_video} elementIndex={v} />
          </div>
        ))}
      <div className="py-60 lg:pb-100  lg:pt-90 xl:pt-1-40 overflow-hidden">
        <div className="px-5-3 md:px-0 pb-4-1 sm:pb-6 lg:pb-50 2xl:pb-70 s-testimonial-video-wrapp text-center justify-between mx-auto">
        <Fade bottom distance="60px" delay={100}>
          <h3 className="pb-1-2 lg:pb-5 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">Client Testimonials</h3>
          </Fade>
          <Fade bottom distance="60px" delay={100}>
          <p className="xl:px-1-10 text-p4 lg:text-p2 font-worksans-normal text-term-primary">Our personalized tools transform businesses and empower individuals to reach their highest potential – here’s what a few of them have to say.</p>
       </Fade>
        </div>

        <div className="testimonial-slide-main">
        <Fade bottom distance="60px" delay={100}>
          <div className="s-testimonial-nav-viewport">
            <Flickity
              className={'px-5-3 md:px-0 s-testimonial-video-nav'}
              elementType={'div'}
              options={flickityNavOptions}>
              {content && content.map(({ node: thumbnail }, i) => (
                <div key={i} className="sv-testimonial-nav-cell">
                  <GatsbyImage className="rounded-full" image={getImage(thumbnail.frontmatter.featured_image.thumbnail)} alt={thumbnail.frontmatter.featured_image.alt || `${thumbnail.frontmatter.title} | DecodeUp Client`} />
                </div>
              ))}
            </Flickity>
          </div>
          </Fade>
          <Fade bottom distance="60px" delay={100}>
          <Flickity
            className={'pl-0 lg:pl-10 xl:pl-66 mt-50 md:mt-6-6 lg:mt-50 s-testimonial-video-main'}
            elementType={'div'}
            options={flickityOptions} >
            {content && content.map(({ node: post }, j) => (
              <div key={j} className="sv-testimonial-cell flex flex-wrap md:items-center lg:items-start">
                <div className="px-5-3 w-full md:w-1/2 lg:w-696  cursor-pointer relative block" onClick={(event) => handleVideoIsOpen(event, j)}>
                  <GatsbyImage className="h-full lg:h-3-62" image={getImage(post.frontmatter.featured_image.image)} alt={post.frontmatter.featured_image.alt || `${post.frontmatter.title} | DecodeUp Client`} />
                  <span className="block absolute w-full h-full flex inset-0 md:items-center md:justify-center group items-center justify-center">
                    <svg className="w-52 md:w-65 lg:w-88 h-52 md:h-65 lg:h-88 md:absolute cursor-pointer group-hover:opacity-75 transition-opacity duration-200 m-5 md:m-0" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
                      <circle fill="#fff" cx="49" cy="49" r="49" />
                      <path fill="#c4c4c4" d="M62.36,48.44,43.15,59.53V37.35Z" />
                    </svg>
                  </span>
                </div>
                <div className="pt-6-6 px-5-3 md:pt-0  xl:pl-87 xl:pr-67 w-full md:w-1/2 lg:w-594">
                  <div className="font-poppings-bold text-rh6 lg:text-h6 text-term-primary opacity-60">HEAR OUR IMPACT</div>
                  <h3 className="mt-1-3 md:mt-0-1 lg:mt-4-2 font-poppings-bold text-rp1 lg:text-h2 text-term-primary">{post.frontmatter.title}</h3>
                  <p className="pr-2 w-3/5 md:w-full font-poppings-normal text-rp4 lg:text-p3-1 text-term-primary">{post.frontmatter.description}</p>
                  <p className="pt-2-3 text-p4 lg:text-p2 font-worksans-normal text-term-primary" dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
              </div>
            ))}
          </Flickity>
          </Fade>
        </div>
      </div>

    </>
  )
}

export default TestimonialsVideo;
